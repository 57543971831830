/*
 * @Author: your name
 * @Date: 2020-12-03 17:05:57
 * @LastEditTime: 2024-07-08 15:08:58
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: \slide-tct\src\api\api.js
 */
import request from '../utils/request'

export default {

 /**
   * 新闻管理
   */

  // 获取焦点新闻
  getFocusNews(num) {
    return request.fetchGet(`api/News/GetFocusNews?num=${num}`);
  },
  // 获取详细新闻信息
  getNewsById(id){
    return request.fetchGet(`api/News/GetNewsByID/${id}`);
  },
  // 分页获取新闻列表
  getNewsByPage(pageIndex) {
    return request.fetchGet(`api/News/GetNewsByPage?pageIndex=${pageIndex}`);
  },
  // 获取新闻总条数
  getNewsCount() {
    return request.fetchGet(`api/News/GetCount`);
  },
  

 /**
   * 招聘管理
   */

  // 分页获取招聘列表
  getRecruitByPage(pageIndex) {
    return request.fetchGet(`api/Recruit/GetRecruitByPage?pageIndex=${pageIndex}`);
  },
  // 获取招聘总条数
  getRecruitCount() {
    return request.fetchGet(`api/Recruit/GetCount`);
  },

  /**
   * 产品管理
   */
  
  // 分页查询产品管理
  getPorductList(pageIndex, pageSize, params) {
    return request.fetchPost(`api/Products/GetProductsByPage?pageIndex=${pageIndex}&pageSize=${pageSize}`, params); 
  },

  // 根据id获取产品关联
  getProdRelationByid(productID) {
    return request.fetchGet(`api/ProductRelation/GetProductRelationByID?ProductID=${productID}`);
  },

  /**
   * 文献管理
   */

  // 获取文献文章
  getDecipherByPage(pageIndex) {
    return request.fetchGet(`api/Articles/GetArticlesByPage?pageIndex=${pageIndex}`);
  },
  // 获取文献文章总条数
  getDecipherCount() {
    return request.fetchGet(`api/Articles/GetCount`);
  },
  // 获取详细文献文章信息
  getDecipherById(id){
    return request.fetchGet(`api/Articles/GetArticleByID/${id}`);
  },


  /**
   * 视频管理
   */
  // 获取视频列表
  getVideoByPage(pageIndex) {
    return request.fetchGet(`api/Video/GetVideoByPage?pageIndex=${pageIndex}`);
  },
  // 获取视频总条数
  getVideoCount() {
    return request.fetchGet(`api/Video/GetCount`);
  },
  // 获取详细视频信息
  getVideoById(id){
    return request.fetchGet(`api/Video/GetVideoID/${id}`);
  },

}